import loadScript from '../utils/load-script';

export const initCaptcha = () => {
  let captchaInterval = null;
  setTimeout(() => {
    loadScript('/vendor/statamic-captcha/js/recaptcha.js?v4', () => {
      if (window.WL.captcha && window.WL.captcha.attachReload) {
        captchaInterval = window.WL.captcha.attachReload();
      }
    });
  }, 3000);
};
