import { gsap } from 'gsap';

export const initScrollToTop = () => {
  document.querySelectorAll('[data-scoll-top]').forEach((el) => {
    el.addEventListener('click', (ev) => {
      ev.preventDefault();
      gsap.to(window, {
        duration: 0.3,
        scrollTo: {
          y: 0,
        },
      });
    });
  });
};
