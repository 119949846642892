import EvEmitter from 'ev-emitter';
import { loadAll, unloadAll } from '../utils/sections/registration.js';
import { initCaptcha } from './captcha.js';
import { initAccessibility } from './accessibility.js';
import { initScrollLinks, scrollToHash } from '../utils/scroll-to-hash.js';
import { initScrollToTop } from './scroll.js';
import './sentry';
import './gsap';
import '../../vendor/statamic-ajax-forms/js/statamic-ajax-forms';
import 'wildlabs-frontend-core/js/utils';
import 'wildlabs-frontend-core/js/forms/file';
import 'wildlabs-frontend-core/js/accordion';
import 'wildlabs-frontend-core/js/tabs';
import 'wildlabs-frontend-core/js/toggleClass';
import '../components/select';
import '../components/modal';
import '../components/announcement';
import '../components/cursor';
import '../components/modal-video';
import '../components/wc-datepicker.min';
import { attachFormEvents, attachPdfDownloadsEvents } from '../utils/analytics.js';

export class AbstractApp extends EvEmitter {
  constructor() {
    super();

    this.app = document.getElementById('main');

    this.beforeRegisterSections();

    this.registerSections().then(() => {
      initAccessibility();
      this.loadSections();
      this.onPageEnter();

      initCaptcha();

      this.afterRegisterSections();
    });
  }

  getSections() {
    return null;
  }

  registerSections() {
    const sections = this.getSections();

    if (sections === null) {
      return Promise.resolve();
    }

    const promises = [];
    for (const path in sections) {
      promises.push(sections[path]());
    }

    return Promise.all(promises);
  }

  onPageEnter() {
    initScrollLinks();
    scrollToHash(window.location.hash);
    initScrollToTop();
    attachFormEvents();
    attachPdfDownloadsEvents();
  }

  beforeRegisterSections() {}
  afterRegisterSections() {}


  loadSections(container = null) {
    loadAll(container || this.app);
  }

  unloadSections(container = null) {
    unloadAll(container || this.app);
  }
}
