export const initCursor = () => {
  const customCursor = document.querySelector('[data-custom-cursor]');
  const cursor = document.querySelector('[data-cursor]');
  if (!customCursor || !cursor) {
    return;
  }

  const cursorStyle = customCursor.style;
  let scheduledAnimationFrame = false;

  const updateCursorPosition = (e) => {
    cursorStyle.top = `${e.clientY}px`;
    cursorStyle.left = `${e.clientX}px`;
    scheduledAnimationFrame = false;
  };

  document.addEventListener('mousemove', e => {
    if (!scheduledAnimationFrame) {
      requestAnimationFrame(() => updateCursorPosition(e));
      scheduledAnimationFrame = true;
    }
  });

  document.addEventListener('mouseover', e => {
    const target = e.target.closest('[data-cursor]');
    if (target) {
      customCursor.classList.add('is-active', `is-${target.dataset.cursor}`);
    }
  });

  document.addEventListener('mouseout', e => {
    const target = e.target.closest('[data-cursor]');
    if (target) {
      customCursor.classList.remove('is-active', `is-${target.dataset.cursor}`);
    }
  });
}

initCursor();
