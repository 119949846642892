// ensure all the needed assets are loaded
import.meta.glob(['../images/**', '../fonts/**', '../icons/**']);
import { AbstractApp } from './shared/abstract-app.js';

class App extends AbstractApp {
  constructor() {
    super();
  }

  getSections() {
    return import.meta.glob('./sections/*.js');
  }
}

window.WL = window.WL || {};
window.WL.app = new App();
