import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: window.SENTRY && window.SENTRY.DSN,
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['precinct-app-web'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
  allowUrls: [/https?:\/\/((assets|images|www)\.)?precinct\.co.nz/],
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event, hint) {
    const error = hint.originalException;
    // Check if the request is from bingbot or googlebot
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('bingbot') || userAgent.includes('googlebot')) {
      // Drop the event if it is from bingbot or googlebot
      return null;
    }
    // Check if the error message includes "antifingerprint not defined yet"
    if (error && error.message && error.message.includes('antifingerprint not defined yet')) {
      // Drop the event if the message matches
      return null;
    }
    // Otherwise, send the event to Sentry
    return event;
  },
});
